import querystring from 'qs';
import { request } from '../arogga-api';

import type { IG_LT_OrderList } from '../types/lab-order/type.getLabOrderList';
import type { IG_LT_OnlinePaymentDetails } from '../types/lab-order/type.getOnlinePaymentDetailsForOrder';
import type { IG_LT_OrderDetails } from '../types/lab-order/type.getOrderDetails';
import type { IG_LT_SampleCollectionSchedule } from '../types/lab-order/type.getSampleCollectionSchedule';
import type { IC_LT_PlaceLabOrder, IC_LT_PlaceLabOrderBody } from '../types/lab-order/type.placeLabOrder';
import type { I_Name, I_ResponseDataArray, I_ResponseDataObj } from '../types/type.lab-test-apis';

export const getLabTestOrders = (q?: any): Promise<I_ResponseDataArray<IG_LT_OrderList>> => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `lab-order/api/v1/orders?${qs}`,
		method: 'GET'
	});
};

export const getLabTestsOrderDetails = (OrderId: string): Promise<I_ResponseDataObj<IG_LT_OrderDetails>> => {
	return request({
		endpoint: `lab-order/api/v1/orders/${OrderId}`,
		method: 'GET'
	});
};

export const cancelLabTestOrder = (id: string, cancelReason: string) => {
	return request({
		endpoint: `lab-order/api/v1/orders/${id}/cancellation`,
		method: 'PUT',
		body: {
			cancelReason
		}
	});
};

export const getLabTestOrderCancelReasons = (): Promise<I_ResponseDataArray<I_Name>> => {
	return request({
		endpoint: `lab-order/api/v1/shared/order-cancel-reasons`,
		method: 'GET'
	});
};

export const placeLabOrder = (body: IC_LT_PlaceLabOrderBody): Promise<I_ResponseDataObj<IC_LT_PlaceLabOrder>> => {
	return request({
		endpoint: `lab-order/api/v1/orders`,
		method: 'POST',
		body: body
	});
};

export const getSampleCollectionSchedule = (): Promise<I_ResponseDataArray<IG_LT_SampleCollectionSchedule>> => {
	return request({
		endpoint: `lab-order/api/v1/shared/schedule-dates`,
		method: 'GET'
	});
};

export const updateSampleCollectionSchedule = (orderId: string, body: any) => {
	return request({
		endpoint: `lab-order/api/v1/orders/${orderId}/rescheduling`,
		method: 'PUT',
		body
	});
};

export const onlinePaymentForLabOrder = (orderId: string): Promise<I_ResponseDataObj<IG_LT_OnlinePaymentDetails>> => {
	return request({
		endpoint: `lab-order/api/v1/orders/${orderId}/payment`,
		method: 'GET'
	});
};
