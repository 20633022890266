import { request } from '../arogga-api';

import type { IC_LT_AddTestToCart } from '../types/lab-cart/type.addTestToCart';
import type { IG_LT_TestCartData } from '../types/lab-cart/type.getTestCartData';
import type { I_ResponseDataObj } from '../types/type.lab-test-apis';

export const getTestsCart = (): Promise<I_ResponseDataObj<IG_LT_TestCartData>> => {
	return request({
		endpoint: 'lab-cart/api/v2/carts/my',
		method: 'GET'
	});
};

export const updateHardCopyConveyance = (isHardCopyRequired: boolean) => {
	return request({
		endpoint: `lab-cart/api/v2/carts/my/hard-copy`,
		method: 'PUT',
		body: {
			isHardCopyRequired
		}
	});
};

export const updateLabCartVendor = (vendorUqid: string) => {
	return request({
		endpoint: `lab-cart/api/v2/carts/my/vendor`,
		method: 'PUT',
		body: {
			vendorUqid
		}
	});
};
export const applyDiscountCoupon = (name: string = '') => {
	return request({
		endpoint: `lab-cart/api/v2/carts/my/coupon`,
		method: 'PUT',
		body: {
			name
		}
	});
};

export const addTestToCart = (
	labItemUqid: string,
	patientCount: number,
	vendorUqid: string
): Promise<I_ResponseDataObj<IC_LT_AddTestToCart>> => {
	return request({
		endpoint: 'lab-cart/api/v2/carts/my/items',
		method: 'POST',
		body: {
			labItemUqid,
			patientCount,
			vendorUqid
		}
	});
};

export const deleteTestCartItem = (cartItemUqid: string) => {
	return request({
		endpoint: `lab-cart/api/v2/carts/my/lab-items/${cartItemUqid}`,
		method: 'DELETE'
	});
};

export const updateTestCartItem = (
	cartItemUqid: string,
	patientCount: number
): Promise<I_ResponseDataObj<IC_LT_AddTestToCart>> => {
	return request({
		endpoint: `lab-cart/api/v2/carts/my/lab-items/${cartItemUqid}`,
		method: 'PUT',
		body: {
			patientCount
		}
	});
};

export const updateCartAddress = (userLocationId: string | number, locationId: string | number) => {
	return request({
		endpoint: `lab-cart/api/v2/carts/my/location`,
		method: 'PUT',
		body: {
			userLocationId,
			locationId
		}
	});
};

export const getCheckoutData = () => {
	return request({
		endpoint: `lab-cart/api/v2/carts/my/checkout`,
		method: 'GET'
	});
};
