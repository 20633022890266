'use client';
import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
// import { LOGIN, LOGOUT, MODAL_CLOSE, MODAL_OPEN, SET_CART_ITEMS_PRICE } from './action-types';

// type StateType = {
// 	isLoggedIn: boolean;
// 	isModalOpen: boolean;
// 	open?: boolean;
// 	setOpen?: (open: boolean) => void;
// 	cartTotal_Price_Items?: any;
// };

// type ActionType = {
// 	type: string;
// 	payload: any;
// };

// const initialState: StateType = {
// 	isLoggedIn: false,
// 	isModalOpen: false
// };

// const reducer = (state: StateType, action: ActionType) => {
// 	switch (action.type) {
// 		case LOGIN:
// 			return { ...state, isLoggedIn: true };
// 		case LOGOUT:
// 			return { ...state, isLoggedIn: false };
// 		case MODAL_OPEN:
// 			return { ...state, isModalOpen: true };
// 		case MODAL_CLOSE:
// 			return { ...state, isModalOpen: false };
// 		case SET_CART_ITEMS_PRICE:
// 			return { ...state, cartTotal_Price_Items: action.payload };

// 		default:
// 			return state;
// 	}
// };

// export const AuthContext = createContext<{
// 	state: StateType;
// 	dispatch: Dispatch<ActionType>;
// 	[key: string]: any;
// }>({ state: initialState, dispatch: () => null });

interface I_AuthContext {
	open: boolean;
	labTestCount: number;
	setLabTestCount: Dispatch<SetStateAction<number>>;
	setOpen: Dispatch<SetStateAction<boolean>>;
	cartTotalPriceItems: I_CartItemsTotalPriceState;
	setcartTotalPriceItems: Dispatch<SetStateAction<I_CartItemsTotalPriceState>>;
	showCheckoutModal: boolean;
	setShowCheckoutModal: Dispatch<SetStateAction<boolean>>;
	userData: I_UserDataState;
	setUserData: Dispatch<SetStateAction<I_UserDataState>>;
}

interface I_UserDataState {
	defaultAddress: string;
	selectedAddress: {
		id: string;
	};
}

interface I_CartItemsTotalPriceState {
	itemCount: number;
	totalAmount: string;
}

export const LabAuthContext = createContext<I_AuthContext | null>(null);

export const LabAuthContextProvider = ({ children }: { children: React.ReactNode }) => {
	// const [state, dispatch] = useReducer(reducer, initialState);
	const [open, setOpen] = useState<boolean>(false);
	const [showCheckoutModal, setShowCheckoutModal] = useState<boolean>(false);
	const [cartTotalPriceItems, setcartTotalPriceItems] = useState<I_CartItemsTotalPriceState>({
		itemCount: 0,
		totalAmount: '0'
	});
	const [userData, setUserData] = useState<I_UserDataState>({
		defaultAddress: '',
		selectedAddress: {
			id: ''
		}
	});
	const [labTestCount, setLabTestCount] = useState<number>(0);

	return (
		<LabAuthContext.Provider
			value={{
				// state,
				// dispatch,
				labTestCount,
				setLabTestCount,
				open,
				setOpen,
				cartTotalPriceItems,
				setcartTotalPriceItems,
				showCheckoutModal,
				setShowCheckoutModal,
				userData,
				setUserData
			}}>
			{children}
		</LabAuthContext.Provider>
	);
};

const useLabAuthContext = () => {
	const context = useContext(LabAuthContext);
	if (!context) {
		throw new Error('useLabAuthContext must be used within a AuthContextProvider');
	}
	return context;
};

export default useLabAuthContext;
