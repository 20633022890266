import { useEffect, useState } from 'react';
import styles from './switch.module.scss';

interface Props {
	checked: boolean;
	onChange: (checked: boolean) => void;
}
const Switch = ({ checked, onChange }: Props) => {
	const [isChecked, setIsChecked] = useState<boolean>(checked);

	// Sync internal state with the external checked prop
	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	const handleChange = () => {
		setIsChecked(!isChecked);
		onChange(!isChecked);
	};

	return (
		<label className={styles.switch}>
			<input type='checkbox' checked={isChecked} onChange={handleChange} />
			<span className={styles.slider + ' ' + styles.round}></span>
		</label>
	);
};

export default Switch;
