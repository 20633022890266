'use client';

import { AuthProvider } from '@/contexts/AuthProvider';
import { CartProvider } from '@/contexts/CartProvider';
import { LoginModelProvider } from '@/contexts/LoginModelProvider';
import { ProductProvider } from '@/contexts/ProductProvider';
import { StickyCartProvider } from '@/contexts/StickyCartProvider';
import { LabAuthContextProvider } from '@labServices/context/LabAuthContext';
import { LabCartContextProvider } from '@labServices/context/LabCartContext';
import { SessionProvider } from 'next-auth/react';
const LayoutWrapper = ({ children }) => {
	return (
		<SessionProvider>
			<AuthProvider>
				<LabAuthContextProvider>
					<LoginModelProvider>
						<CartProvider>
							<LabCartContextProvider>
								<StickyCartProvider>
									<ProductProvider>{children}</ProductProvider>
								</StickyCartProvider>
							</LabCartContextProvider>
						</CartProvider>
					</LoginModelProvider>
				</LabAuthContextProvider>
			</AuthProvider>
		</SessionProvider>
	);
};

export default LayoutWrapper;
